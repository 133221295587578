import * as S from "./styles";

import { Col, Row } from "antd";

import Fade from "react-reveal/Fade";
import { lazy } from "react";
import { withTranslation } from "react-i18next";

const Button = lazy(() => import("../../common/Button"));

const MiddleBlock = ({ title, content, button, t, id, titleSize }) => {
  const scrollTo = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({
      behavior: "smooth",
    });
  };
  return (
    <S.MiddleBlock>
      <Row type="flex" justify="center" align="middle" id={id}>
        <Fade bottom>
          <S.ContentWrapper>
            <Col lg={24} md={24} sm={24} xs={24}>
              <h6>{t(title)}</h6>
              <S.Content>
                {titleSize || false ? (
                  <ptitle>{t(content)}</ptitle>
                ) : (
                  <p>{t(content)}</p>
                )}
              </S.Content>
              {button ? (
                <Button
                  name="submit"
                  type="submit"
                  onClick={() => scrollTo("mission")}
                >
                  {t(button)}
                </Button>
              ) : (
                ""
              )}
            </Col>
          </S.ContentWrapper>
        </Fade>
      </Row>
    </S.MiddleBlock>
  );
};

export default withTranslation()(MiddleBlock);
